<template>
    <div class="vx-row p-8 max-w-lg mx-auto flex justify-center">
        <div class="w-full">
            <div class="pt-4">
                <div class="create-item-container vx-col w-full mb-10 text-right">
                    <div class="flex flex-row-reverse">
                        <button v-if="canBeEdited" @click.stop="createItem" class="xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary">
                            {{ $t("apps.configurator.menu-list.create-item") }}
                        </button>
                        <button v-if="menu.type !== 'tab'" @click.stop="previewMenu" class="xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary btn-border mr-2">
                            <feather-icon icon="EyeIcon" svgClasses="text-primary align-middle w-6" class="align-middle" />
                        </button>
                        <emptyAlert :message="$t('apps.configurator.alert-empty.item-text')" v-if="menu.items.length === 0" class="mr-5 flex-grow"/>
                    </div>
                </div>

                <div v-show="action === 'list'">
                    <div class="vx-col w-full mb-10" v-if="menu.items.length > 0">
                        <MenuTree
                            :menu="menu"
                            :language="version.mainLanguage()"
                            @editItem="editItem"
                            @deleteItem="deleteItem"
                            @insertItemAfter="insertItemAfter"
                            @insertItemInside="insertItemInside"
                            @nodeOpenChanged="$emit('nodeOpenChanged')"
                            :readOnly="!canBeEdited"
                        />
                    </div>
                    <div v-if="canBeEdited" class="action-item-button-container vx-col w-full">
                        <button @click.stop="cancel" class="xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary btn-border mr-2">
                            {{ $t("apps.configurator.menu-list.cancel") }}
                        </button>
                        <button @click.stop="save" class="xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary">
                            {{ $t("apps.configurator.menu-list.save") }}
                        </button>
                    </div>
                </div>

                <div v-show="action === 'form'">
                    <ManageMenuItemForm
                        ref="manageMenuItemForm"
                        :languages="version.languages"
                        :templates="version.templatesByTimeofyearAndType(menu.timeofyear, menu.type)"
                        :menu="menu"
                        :resources="[...version.customerResources, ...version.resourcesByPlatformAndTimeofyear('android', menu.timeofyear)]"
                        v-bind="manageMenuItemFormProps"
                        @change="action = 'list'; $emit('nodeOpenChanged')"
                        @cancel="action = 'list'; $emit('nodeOpenChanged')"
                        @loaded="$emit('nodeOpenChanged')"
                        :readOnly="!canBeEdited"
                    />
                </div>

                <div v-show="action === 'preview'">
                    <MenuPreviewSection
                        ref="menuPreviewSection"
                        :version="version"
                        :menu="menu"
                        @cancel="action = 'list'; $emit('nodeOpenChanged')"
                        @loaded="$emit('nodeOpenChanged')"
                    />
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import {Version} from "@/modules/Apps/Models/Version";
import EmptyAlert from "@/modules/Shared/Components/alert/emptyAlert.vue";
import MenuTree from "@/modules/Apps/Components/configurator/MenuTree.vue";
import ConfiguratorMenuService from "@/modules/Apps/Services/Configurator/ConfiguratorMenuService";
import loader from "@/modules/Shared/Mixins/loader";
import ManageMenuItemForm from "@/modules/Apps/Components/configurator/ManageMenuItemForm.vue";
import MenuPreviewSection from "@/modules/Apps/Components/configurator/MenuPreviewSection.vue";

export default {
    name: 'MenuCollapseItemContent',
    mixins: [loader],
    props: {
        version: {
            type: Version,
            required: true
        },
        menu: {
            type: Object,
            required: true
        },
    },
    computed: {
        canBeEdited() {
            return !this.version.isPublished() || 
                (this.version.isPublished() && this.$store.state.auth.drm['admin'].length > 0) 
        }  
    },
    data() {
        return {
            manageMenuItemFormProps: {},
            action: 'list'
        }
    },
    components: {
        MenuPreviewSection,
        ManageMenuItemForm,
        MenuTree,
        EmptyAlert
    },
    methods: {
        async previewMenu() {
            if (this.canBeEdited) {
                await this.save()
            }
            this.action = 'preview'
            await this.$refs['menuPreviewSection'].open()
        },
        createItem() {
            this.action = 'form'
            this.manageMenuItemFormProps = {}
            this.$refs['manageMenuItemForm'].open()
        },
        editItem(item) {
            this.action = 'form'
            this.manageMenuItemFormProps = {
                item: item
            }
            this.$refs['manageMenuItemForm'].open()
        },
        insertItemAfter(item) {
            this.action = 'form'
            this.manageMenuItemFormProps = {
                item: null,
                afterItemUuid: item.uuid
            }
            this.$refs['manageMenuItemForm'].open()
        },
        insertItemInside(item) {
            this.action = 'form'
            this.manageMenuItemFormProps = {
                item: null,
                insideItemUuid: item.uuid
            }
            this.$refs['manageMenuItemForm'].open()
        },
        deleteItem(item) {
            this.deleteItemInItemList(item, this.menu.items)
        },
        deleteItemInItemList(itemToDelete, itemsList) {
            const itemIndex = itemsList.findIndex(i => i.uuid === itemToDelete.uuid)
            if (itemIndex !== -1) {
                itemsList.splice(itemIndex, 1)
                return
            }

            itemsList.map((item) => { 
                this.deleteItemInItemList(itemToDelete, item.submenu_items || [])
            })
        },
        async cancel() {
            await this.loadAndNotify(async () => {
                const menu = await ConfiguratorMenuService.getMenu(this.menu.uuid)
                const menuIndex = this.version.menus.findIndex(m => m.uuid === menu.uuid)
                this.version.menus.splice(menuIndex, 1, menu)
            }, true)
        },
        async save() {
            await this.loadAndNotify(async () => {
                await ConfiguratorMenuService.editMenu(this.menu.uuid, {
                    ...this.menu,
                    resorts: this.menu.resorts,
                    version_uuid: this.version.version.uuid
                })
            })
        }
    },
}
</script>
